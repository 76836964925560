//base
@import './base/import';

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

body {
  font-size: var(--font-base);
}

.custom-modal {
  width: var(--create-modal-w);
}

.pt-nav {
  padding-top: 80px !important;
}

.bottomLine {
  border-bottom: 0.2px solid var(--col-dark) !important;
  opacity: 0.2 !important;
}

.bottomLine-header {
  border-bottom: 0.2px solid var(--col-dark) !important;
}

.flow-icon {
  svg {
    width: 20px !important;
    height: 20px !important;
  }

  width: 20px !important;
  height: 20px !important;
}

.textButton {
  color: var(--col-prime) !important;
}

.ai-model {
  width: 500px;
}

.selected-block {
  background-color: var(--col-primary-20) !important;
  color: white !important;
}
.selected-block-light {
  background-color: var(--col-primary-10) !important;
}
.active-block-light {
  background-color: #ebfeee !important;
}
.info-color {
  background-color: var(--col-info) !important;
}

.hovered-class {
  background-color: var(--col-accent) !important;
  color: black !important;
}

.selected-block .opacity-0 {
  opacity: 1 !important;
}

.disabled {
  opacity: 0.5 !important;
}

.Accordian {
  width: 18vw !important;
  background-color: transparent !important;
}

.pluginLogo {
  width: 42px;
  margin-right: 6px;
}

.width-when-show-ai {
  width: 42vw !important;
}

.componentspagebox {
  display: flex !important;
  padding: 8px;
  flex-direction: column !important;
  background-color: beige !important;
}

// .log-block:has(.logBlocks) {
//     border-bottom: 1px solid;
//     border-image: linear-gradient(to right, rgb(167, 167, 167), rgba(167, 167, 167, 0)) 1;
//     /* Adjust the direction and colors as needed */
// }

// .log-block:has(.selectedBlock){
//     border-bottom: 1px solid;
//     border-image: linear-gradient(to right, rgb(255, 255, 255), rgba(167, 167, 167, 0)) 1;
// }

.mainLogBlocksContainer {
  min-width: 600px;
  height: 100vh;
}

.title-textfield:focus-within {
  opacity: 0.7 !important;
}

.logsAndFlow {
  margin-left: 250px !important;
}

.logHeadIcons {
  font-size: 14px !important;
}

.right-slider-width {
  width: var(--right-slider-w) !important;
  box-sizing: border-box !important;
}

.mainLogBlocksValueContainer {
  min-width: 370px !important;
  margin-right: 10px;
  margin-top: 50px;
  position: sticky;
  bottom: 10px;
  padding: 10px 15px;
  height: 100vh;
  box-sizing: border-box;
  overflow: scroll;
}

.ipValueForLog {
  font-size: 12px;
}

.sendIcon {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.loadingicon {
  right: 10px;
  transform: translateY(-50%);
}

//blockstyling
.project-block {
  height: 30px !important;
}

.create-block {
  color: var(--create-btn-color) !important;
}

.block {
  cursor: pointer;

  &:hover {
    background-color: var(--col-primary-5);

    .opacity-0 {
      opacity: 1 !important;
    }
  }
}

.disabled-block {
  opacity: 0.4;
}

.line-height {
  line-height: normal !important;
}

.font-1rem {
  font-size: 1rem !important;
}

.font-2rem {
  font-size: 2rem !important;
}

.font-3rem {
  font-size: 3rem !important;
}

.slider-title-box {
  height: 35px !important;
}

.trigger-padding {
  padding-left: 20px !important;
}

.project-icon {
  width: 18px !important;
}

// Global search Heading

.css-1rt9ipu-MuiInputBase-root-MuiTablePagination-select,
.MuiTablePagination-input {
  width: unset !important;
}

.MuiBox-root {
  box-sizing: border-box !important;
}

.MuiCollapse-root {
  width: 100% !important;
}

.dialogMinMax {
  min-width: var(--dilaog-min-width) !important;
  max-width: var(--dilaog-max-width) !important;
}
.dialogMax {
  max-width: var(--dilaog-max-width) !important;
}

.event-box {
  cursor: pointer !important;
  padding: 16px;
  width: 240px !important;
  height: 65px;
  gap: 12px;

  &__selected {
    background-color: rgba(30, 117, 186, 0.05);
    pointer-events: none;
  }

  &:hover {
    background-color: rgba(30, 117, 186, 0.02);
  }

  &__with-description {
    width: 280px !important;
    height: 120px !important;

    .event-box-text {
      -webkit-line-clamp: 3 !important;
    }
  }

  &__fullwidth {
    width: 100% !important;
  }

  &__with-integration {
    height: 200px !important;
    padding: 0 !important;
    width: 100% !important;

    &:hover {
      background-color: transparent !important;

      .opacity-0 {
        opacity: 1 !important;
      }
    }
  }

  &__footer {
    border-top: 0.2px solid #ccc;
  }

  border: 1px solid rgba(233, 231, 226, 1);

  .event-box-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
  }
}

.events-container {
  gap: 12px;
}

.flow-description {
  color: rgb(107, 111, 118) !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.current-enabled-flow-box {
  padding: 16px;
  height: 113px !important;
  width: 240px !important;
  border: 1px solid rgba(233, 231, 226, 1);
  background-color: var(--col-primary-20);

  &:hover {
    .actionBtnContainer {
      opacity: 1 !important;
    }
  }

  .flow-box-text {
    cursor: text;
    word-wrap: pre-wrap;
    text-overflow: ellipsis !important;
    overflow-x: hidden;
  }
}

.enabled-flow-box {
  padding: 12px;

  &__embed {
    padding: 0;
  }

  &:hover {
    background-color: var(--col-primary-5);
  }

  height: 122px !important;
  width: 240px;
  border: 1px solid rgba(233, 231, 226, 1);

  background-color: rgba(255, 255, 255, 1);

  .flow-box-text {
    cursor: text;
    word-wrap: pre-wrap;
    text-overflow: ellipsis !important;
    overflow-x: hidden;
  }
}

.enabled-script-flow-box {
  padding: 16px;

  &:hover {
    background-color: var(--col-primary-5);
  }

  height: 113px !important;
  width: 240px !important;
  border: 1px solid rgba(233, 231, 226, 1);
  background-color: rgba(255, 255, 255, 1);

  &:hover {
    .actionBtnContainer {
      opacity: 1 !important;
    }
  }

  .flow-box-text {
    cursor: text;
    word-wrap: pre-wrap;
    text-overflow: ellipsis !important;
    overflow-x: hidden;
  }
}

.searchbar-width {
  width: 300px;
}

a .hover-fit {
  width: 320px;
}

.info-color {
  cursor: pointer;
  color: var(--col-link);

  &:hover {
    text-decoration: underline;
  }
}
.color-link {
  color: var(--col-link);
}

.header-iframe-title {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

@media (max-width: 890px) {
  .header-iframe-preview-section,
  .header-iframe {
    flex-wrap: wrap;
  }
}

textarea {
  outline: none !important;
}

.h-50vh {
  height: fit-content !important;
}

.h-50 {
  height: 50% !important;
}

.published_workflow {
  background-color: #fffdf4 !important;
}

.step-draggable-container {
  min-height: 36px !important;
}

.workflow__flow {
  width: 550px !important;
  max-width: 550px !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: #000 !important;
  /* Replace with your desired color */
}

.googlemeet-texarea {
  padding: 12px !important;
  font-family: 'Inter', sans-serif !important;
}

.icon-wrapper-box {
  border: 0.1px solid rgb(239, 239, 239);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px;
  border-radius: 2px !important;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
  backdrop-filter: blur(10px) /* Blurry effect */;
  -webkit-backdrop-filter: blur(10px); /* For Safari browser compatibility */
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  direction: rtl;
}

.inputcontainer {
  max-width: 300px !important;
}

.add-trigger-container {
  border: 0.1px solid #b7b7b7;
  background: #f6f6f6;
  // box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.1);

  .search_textfield {
    background-color: #eeeeee !important;
  }

  .service-option {
    &:hover {
      font-weight: bold !important;
      background-color: #333333 !important;
      color: white !important;
    }
  }
}

.form-billing-parent-box {
  &__container {
    max-width: 500px !important;
  }

  background-color: #f6f4ee;
  padding: 40px 100px;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.pre-word-wrap {
  white-space: pre-wrap;
  word-break: break-all !important;
}

.container {
  container-type: inline-size;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

@container (max-width: 500px) {
  .grid-item {
    grid-column: span 12;
  }
}

@container (min-width: 500px) and (max-width: 700px) {
  .grid-item {
    grid-column: span 6;
  }

  .grid-item-small {
    grid-column: span 4;
  }
}

@container (min-width: 700px) and (max-width: 1100px) {
  .grid-item {
    grid-column: span 4;
  }

  .grid-item-small {
    grid-column: span 3;
  }
}

@container (min-width: 1100px) {
  .grid-item {
    grid-column: span 3;
  }

  .grid-item-small {
    grid-column: span 2;
  }
}

.code-accordion {
  padding: 14.8px 8px !important;
}

.brand-label {
  height: 4px !important;
}

.filter-services-box {
  max-height: 800px !important;
}

.transparent-bg {
  background-color: rgb(255, 255, 255, 0.6);
  // box-shadow: #eaeaea 1px 1px 5px;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.org_text_wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 235px;
  display: block;
  position: relative;
}

.org-row:hover {
  & .opacity-0 {
    opacity: 1 !important;
  }
}

.gallery-container {
  max-width: 1100px;
  height: 70% !important;
}

.visible-child-hover:hover {
  & .opacity-0 {
    opacity: 1 !important;
  }
}

.shadow-none {
  box-shadow: none !important;
}

.transform-8px {
  transform: translateX(8px);
}
.recommendation-box {
  border: 1px solid #ccc !important;
}
.color-success {
  color: var(--col-success);
}

.border-bottom-none {
  border-bottom: none !important;
}
.border-top-1 {
  border-top: 1px solid #ccc !important;
}

.selected-block-border-bottom {
  border-bottom: 2px solid var(--col-primary-20) !important;
}
p {
  margin-block: 0 !important;
}

.show-hide-onhover {
  .show-onhover {
    display: none;
    cursor: pointer;
  }
  &:hover .show-onhover {
    display: flex !important;
  }
  &:hover .hide-onhover {
    display: none !important;
  }
}
/* button */
.aifield {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 5px;
}
.aifield::after {
  content: '';
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 5px;
}
/* glow */
.aifield::before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 5px;
  opacity: 1; /* Keep the hover state always */
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.aifield:active:after {
  background: transparent;
}

.aifield:active {
  color: #000;
  font-weight: bold;
}

.saveButtonV3Class {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 999 !important;
  background-color: var(--col-right-slider);

  @media (max-width: 800px) {
    position: fixed !important;
    bottom: 0 !important;
    width: 100% !important;
  }
}

// this is for show 3 dots on hover
.visibleOnHover {
  visibility: hidden !important;
}

.customTable-row:hover .visibleOnHover {
  visibility: visible !important;
}

.leave-btn {
  background-color: black;
  color: white;
  padding: 5px;
}

.search-and-ai {
  height: 60px;
  width: 450px;
}

.search-and-ai-closed {
  height: 35px;
  width: 40px;
  background-color: black;
}

.open-chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;

  pointer-events: none !important;
  z-index: 99;
}

.ai-div {
  border: 2px solid transparent;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #ff4e50 0%, #fc913a 20%, #f9d423 40%, #e1f5c4 60%, #24c6dc 80%, #ff4e50 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: box-shadow 0.3s ease-in-out, background-position 0.3s linear;
  background-size: 200% 100%;
}

.project-slider-ai-div {
  border: 2px solid #ccc;
}

.ai-div:hover {
  background-position: 150% 0;
}

.disabled-ai-div {
  border: 2px solid #ccc;
}

.authentication-success-modal {
  @apply p-3 w-full;
}

.authentication-hover-effect {
  @apply cursor-pointer hover:bg-white transition-all duration-300 border-y;
}

.authentication-success-done-button {
  @apply px-3 !my-2 !mr-2 !self-end;
}

.toggle-cards-first-half {
  @apply flex items-center gap-2;
}

.toggle-cards {
  @apply cursor-pointer flex flex-col gap-2 p-3 border hover:bg-[var(--col-light-grey)] transition-all duration-300;
}

.recommendation-heading {
  @apply p-4 !flex !items-center !justify-center !text-center !gap-2 !mx-auto !text-base !bg-white/50 !w-full;
}

.workflow-background {
  background-color: rgba(250, 250, 250, 1) !important;
}
