.authpluginbox,
.authactions {
  border: var(--col-grey-one) 1px solid;
  background-color: var(--col-grey-three);
}

.authdatabox,
.plugdatabox {
  border: 0.2px solid #939292;
  padding: calc(4 * var(--u-base)) calc(0 * var(--u-base));
  padding-left: calc(4 * var(--u-base));
  padding-right: calc(4 * var(--u-base));
  cursor: pointer;
  box-sizing: border-box !important;
  background-color: #ededed;
  transition: background-color 0.3s, box-shadow 0.3s, border 0.3s;
}

.plugdatabox {
  max-width: fit-content;
}

.authdatabox:hover,
.plugdatabox:hover {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.org-level {
  text-decoration: underline;
  color: blue;
  font-weight: bold;
}

.event-page-actions {
  @apply flex flex-row-reverse items-center justify-end py-2 px-3 hover:bg-[var(--col-grey-three)] hover:rounded-md cursor-pointer;
}
